export default [
  {
    id: 1,
    permiso: true,
    group: "/tableros",
    model: false,
    icon: " mdi-chart-bar",
    title: "Tableros",
    children: [
      {
        id: 11,
        permiso: true,
        icon: "mdi-adjust",
        title: "Indicadores",
        to: "indicadores",
      },
      {
        id: 299,
        icon: "mdi-adjust",
        title: "Transporte",
        to: "transporte",
      },
      {
        id: 300,
        icon: "mdi-adjust",
        title: "Servicios",
        to: "servicio",
      },
      {
        id: 301,
        icon: "mdi-adjust",
        title: "Ventas",
        to: "ventas",
      },

    ],
  },

  { // produccion
    id: 2,
    permiso: true,
    group: "/produccion",
    model: false,
    icon: "mdi-factory",
    title: "Produccion",
    children: [
      {
        id: 24,
        permiso: true,
        icon: "mdi-adjust",
        title: "Inventario inicial",
        to: "inventarioInicial",
      },
      {
        id: 23,
        permiso: true,
        icon: "mdi-adjust",
        title: "Almacén",
        to: "almacen",
      },
      {
        id: 21,
        permiso: true,
        icon: "mdi-adjust",
        title: "Producción",
        to: "produccion",
      },
      {
        id: 22,
        permiso: true,
        icon: "mdi-adjust",
        title: "Parte diario",
        to: "partesDiariosProduccion",
      },
      {
        id: 25,
        permiso: true,
        icon: "mdi-sack",
        title: "Ingresos Para Reproceso",
        to: "ingresosReproceso",
      },
      {
        id: 26,
        permiso: true,
        icon: "mdi-sack",
        title: "Reproceso",
        to: "reproceso",
      },
      {
        id: 27,
        permiso: true,
        icon: "mdi-sack",
        title: "Salida",
        to: "salida",
      },

    ],
  },// fin produccion

  { // servicios
    id: 3,
    permiso: true,
    group: "/servicios",
    model: false,
    icon: "mdi-gauge",
    title: "Servicios",
    children: [

      { // transporte
        id: 31,
        permiso: true,
        to: "transporte",
        model: false,
        icon: "mdi-dump-truck",
        title: "Transporte",
      },// fin transporte
      { // otros servicios
        id: 32,
        permiso: true,
        to: "varios",
        model: false,
        icon: "mdi-shape-plus",
        title: "Varios",
      },// otros servicios


    ],
  },// fin servicios

  { // Ventas>
    id: 4,
    permiso: true,
    group: "/ventas",
    model: false,
    icon: "mdi-cash-multiple",
    title: "Ventas",
    children: [
      {
        id: 41,
        permiso: true,
        icon: "mdi-adjust",
        title: "Ventas Arroz",
        to: "ventas",
      },
      {
        id: 42,
        permiso: true,
        icon: "mdi-adjust",
        title: "Ventas Otros",
        to: "ventas_otros",
      }

    ],
  },// Ventas>
  { //Compras
    id: 99,
    permiso: true,
    group: "/compras",
    model: false,
    icon: "mdi-cash-multiple",
    title: "Compras",
    children: [
      {
        id: 990,
        permiso: true,
        icon: "mdi-adjust",
        title: "Compras",
        to: "compras",
      }
    ],
  },// Compras >
  { //Proveedores
    id: 200,
    permiso: true,
    group: "/proveedores",
    model: false,
    icon: "mdi-account",
    title: "Proveedores",
    children: [
      {
        id: 201,
        permiso: true,
        icon: "mdi-adjust",
        title: "Proveedores",
        to: "proveedores",
      },
      {
        id: 202,
        permiso: true,
        icon: "mdi-adjust",
        title: "Cuentas",
        to: "cuentas",
      }
    ],
  },// Proveedores >
  { // Cuentas x pagar
    id: 40,
    permiso: true,
    group: "/cuentasxpagar",
    model: false,
    icon: "mdi-calendar-check",
    title: "Cuentas por pagar",
    children: [

      { // transporte
        id: 401,
        permiso: true,
        group: "/cuentasxpagar/bancos",
        model: false,
        //icon: "mdi-calendar",
        title: "Bancos",
        children: [

          {
            id: 4011,
            permiso: true,
            icon: "mdi-adjust",
            title: "Bancos",
            to: "bancos",
          },
          {
            id: 4012,
            permiso: true,
            icon: "mdi-adjust",
            title: "Préstamos",
            to: "prestamos",
          },
          {
            id: 4013,
            permiso: true,
            icon: "mdi-adjust",
            title: "Cuotas",
            to: "cuotas",
          },
          {
            id: 4014,
            permiso: true,
            icon: "mdi-adjust",
            title: "Pendientes",
            to: "pendientes",
          }

        ],
      },// fin transporte


    ],
  },// fin Cuentas x pagar
  { // agricultores
    id: 5,
    permiso: true,
    group: "/agricultores",
    model: false,
    icon: "mdi-account-cowboy-hat-outline",
    title: "Agricultores",
    children: [
      {
        id: 56,
        permiso: true,
        group: "/agricultores/liquidaciones",
        model: false,
        //icon: "mdi-clipboard",
        title: "Liquidaciones",
        children: [

          {
            id: 561,
            permiso: true,
            icon: "mdi-adjust",
            title: "Liquidar",
            to: "liquidar",
          },
          {
            id: 562,
            permiso: true,
            icon: "mdi-adjust",
            title: "Liquidaciones",
            to: "liquidaciones",
          }

        ],
      },


      {
        id: 876,
        permiso: true,
        group: "/agricultores/cascara",
        model: false,
        //icon: "",
        title: "Compra Arroz-Cáscara ",
        children: [

          {
            id: 877,
            permiso: true,
            icon: "mdi-adjust",
            title: "Ingreso para compra",
            to: "ingreso",
          },
          {
            id: 878,
            permiso: true,
            icon: "mdi-adjust",
            title: "Compra Arroz-Cáscara",
            to: "compra",
          }

        ],
      },
      {
        id: 51,
        permiso: true,
        icon: "mdi-adjust",
        title: "Estados de cuentas",
        to: "estado_cuenta_agricultor",
      },
      {
        id: 52,
        permiso: true,
        icon: "mdi-account",
        title: "Agricultores",
        to: "agricultores",
      },
      {
        id: 53,
        permiso: true,
        icon: "mdi-adjust",
        title: "Lotes",
        to: "lotes",
      },
      {
        id: 54,
        permiso: true,
        icon: "mdi-adjust",
        title: "Ingreso de arroz cáscara",
        to: "arrozcascara",
      },
      {
        id: 55,
        permiso: true,
        icon: "mdi-clipboard",
        title: "Habilitaciones",
        to: "habilitaciones",
      },


    ],
  },// fin agricultores
  { // clientes
    id: 6,
    permiso: true,
    group: "/clientes",
    model: false,
    icon: "mdi-account",
    title: "Clientes",
    children: [
      {
        id: 61,
        permiso: true,
        icon: "mdi-adjust",
        title: "Clientes",
        to: "clientes",
      },
      {
        id: 62,
        permiso: true,
        icon: "mdi-adjust",
        title: "Estados de cuentas",
        to: "estado_cuenta_cliente",
      }

    ],
  },// clientes


  { // trabajadores
    id: 7,
    permiso: true,
    group: "/trabajadores",
    model: false,
    icon: "mdi-account-hard-hat",
    title: "Trabajadores",
    children: [
      {
        id: 71,
        permiso: true,
        icon: "mdi-adjust",
        title: "Trabajadores",
        to: "trabajadores",
      }

    ],
  },// fin trabajadores
  { // MANTENIMIENTO
    id: 8,
    permiso: true,
    group: "/mantenimiento",
    model: false,
    icon: "mdi-warehouse",
    title: "Mantenimiento",
    children: [
      {
        id: 81,
        permiso: true,
        icon: "mdi-adjust",
        title: "Zonas",
        to: "zonas",
      },
      {
        id: 82,
        permiso: true,
        icon: "mdi-adjust",
        title: "Variedades",
        to: "variedades",
      },
      {
        id: 83,
        permiso: true,
        icon: "mdi-adjust",
        title: "Molinos",
        to: "molinos",
      },
      {
        id: 84,
        permiso: true,
        icon: "mdi-adjust",
        title: "Vasijas",
        to: "vasijas",
      },
      {
        id: 85,
        permiso: true,
        icon: "mdi-adjust",
        title: "Productos",
        to: "productos",
      },
      {
        id: 86,
        permiso: true,
        icon: "mdi-adjust",
        title: "Vehículos",
        to: "vehiculos",
      },
      {
        id: 87,
        permiso: true,
        icon: "mdi-adjust",
        title: "Servicios",
        to: "servicios",
      },
      {
        id: 88,
        permiso: true,
        icon: "mdi-adjust",
        title: "Insumos",
        to: "insumos",
      },


    ],
  },// fin MANTENIMIENTO
  { // usuarios
    id: 9,
    permiso: true,
    group: "/Accesos",
    model: true,
    icon: "mdi-security",
    title: "Accesos",
    children: [
      {
        id: 91,
        permiso: true,
        icon: "mdi-adjust",
        title: "Usuarios",
        to: "usuarios",
      },
      {
        id: 92,
        permiso: true,
        icon: "mdi-adjust",
        title: "Permisos",
        to: "permisos",
      },
      {
        id: 93,
        permiso: true,
        icon: "mdi-adjust",
        title: "Datos molino",
        to: "datosMolino",
      },
      {
        id: 94,
        permiso: true,
        icon: "mdi-adjust",
        title: "Datos personales",
        to: "perfil",
      }

    ],
  },
  { // configuraciones
    id: 101,
    permiso: true,
    group: "/configuraciones",
    model: true,
    icon: "mdi-cog",
    title: "Configuraciones",
    children: [
      {
        id: 102,
        permiso: true,
        icon: "mdi-adjust",
        title: "Generales",
        to: "generales",
      }


    ],
  }// fin configuraciones
];